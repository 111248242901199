import { useQuery, useLazyQuery, gql } from "@apollo/client"
import React, { useContext, useEffect, useState } from "react"
import Presentation from "@/components/presentation/Presentation"
import Slide from "@/components/slide/Slide"
import { SearchEmptySvg } from "@/svg/EmptyContainerSvgs"
import { ErrorPlaceholder, MainLoader } from "@/components/utils/placeholders"
import { getUrlParameter } from "@/components/utils/helpers"
import { UserDataContext } from "@/hooks/UserDataHook"
import { Collapse } from "react-collapse"
import SearchedSlideInfo from "../slide/SearchedSlideInfo"
import SlidePlaceholder from "../slide/SlidePlaceholder"
import slugify from "slug"
import { Link, useHistory } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import { useIsInsidePowerPoint } from "../../context/IsInsidePowerPointContext"

export const SEARCH = gql`
  query search($query: String!, $offset: Int, $onlyTags: Boolean) {
    search(query: $query, offset: $offset, onlyTags: $onlyTags) {
      query
      collections {
        id
        batchId
        progress
        name
        mode
        urlToken
        creator {
          id
          firstName
          lastName
        }
        updatedAt
        category {
          _id
        }
        accessibleByUsers {
          _id
          firstName
          lastName
        }
        accessibleByUnits {
          _id
          name
        }
        accessibleByAll
        slides {
          id
          slideId
          blueprintId
          name
          thumbUrl
          downloadUrl
          state
          isFavourite
        }
      }
      presentations {
        id
        batchId
        progress
        name
        isFavourite
        isMyPresentation
        mode
        thumbUrl
        urlToken
        labels
        creator {
          id
          firstName
          lastName
        }
        updatedAt
        category {
          _id
        }
        sharedPresentationLinks {
          isActive
          token
          isDownloadable
          _id
        }
        accessibleByUsers {
          _id
          firstName
          lastName
        }
        accessibleByUnits {
          _id
          name
        }
        accessibleByAll
        slides {
          id
          slideId
          thumbUrl
          blueprintId
          name
          downloadUrl
          state
          isFavourite
        }
      }
      slidesTotalCount
      slides {
        id
        slideId
        blueprintId
        name
        thumbUrl
        tags
        downloadUrl
        state
        texts
        isFavourite
        snippet
        presentations {
          id
          name
          updatedAt
          urlToken
          isMyPresentation
          creator {
            id
            firstName
            lastName
          }
        }
        dupes {
          id
          slideId
          blueprintId
          name
          thumbUrl
          tags
          downloadUrl
          state
          texts
          isFavourite
          presentations {
            id
            name
            updatedAt
            urlToken
            thumbUrl
            isMyPresentation
            creator {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`

const SearchContainer = () => {
  const [openedBatch, setOpenedBatch] = useState(null)
  const [searchData, setSearchData] = useState()
  const [openedDupesSlideId, setOpenedDupesSlideId] = useState(null)
  const [slidesOffset, setSlidesOffset] = useState(0)
  const isInsidePowerPoint = useIsInsidePowerPoint()
  const searchTabs = {
    everywhere: "everywhere",
    tagsSlides: "tagsSlides"
  }
  const [activeTab, setActiveTab] = useState(searchTabs.everywhere)
  const { user } = useContext(UserDataContext)
  const history = useHistory()
  const { data, loading, error, refetch, fetchMore } = useQuery(SEARCH, {
    variables: {
      query: getUrlParameter(location.search, "query"),
      offset: 0,
      onlyTags: false
    },
    onCompleted: (data) => {
      setSearchData(data.search)
      setSlidesOffset(data?.search.slides.length)
    },
    context: { isUsingNewScApi: true },
    fetchPolicy: "network-only" // Always fetch from the server to get the most recent data
  })

  const [getNewSearchOnlyTags, newSearchOnlyTags] = useLazyQuery(SEARCH, {
    variables: {
      query: getUrlParameter(location.search, "query"),
      offset: 0,
      onlyTags: true
    },
    onCompleted: (data) => {
      setSearchData(data.search)
      setSlidesOffset(data?.search.slides.length)
    },
    context: { isUsingNewScApi: true },
    fetchPolicy: "network-only" // Always fetch from the server to get the most recent data
  })

  useEffect(() => {
    if (data?.search) {
      setSearchData(data.search)
    }
  }, [data])

  useEffect(() => {
    if (newSearchOnlyTags?.data?.search) {
      setSearchData(newSearchOnlyTags.data.search)
    }
  }, [newSearchOnlyTags?.data])

  useEffect(() => {
    setSlidesOffset(searchData?.slides.length)
  }, [activeTab])

  const fetchNext = async () => {
    try {
      if (activeTab === searchTabs.tagsSlides) {
        const fetchMoreResult = await newSearchOnlyTags.fetchMore({
          variables: {
            offset: slidesOffset
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            return {
              search: {
                ...prev.search,
                slides: [...prev.search.slides, ...fetchMoreResult.search.slides]
              }
            }
          }
        })
        setSlidesOffset(slidesOffset + fetchMoreResult?.data.search.slides.length)
      } else {
        const fetchMoreResult = await fetchMore({
          variables: {
            offset: slidesOffset
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            return {
              search: {
                ...prev.search,
                slides: [...prev.search.slides, ...fetchMoreResult.search.slides]
              }
            }
          }
        })
        setSlidesOffset(slidesOffset + fetchMoreResult.data.search.slides.length)
      }
    } catch (error) {
      console.error("Error fetching more data:", error)
    }
  }

  const handleViewBatch = (urlToken, name, slides) => {
    history.push({ pathname: `/dashboard/${urlToken}/${slugify(name)}`, state: { slides, currentPathname: name } })
  }

  if (loading || !searchData) return <MainLoader />
  if (error) return <ErrorPlaceholder />

  const { cart } = user

  return (
    <div
      className={`flex justify-center ${
        isInsidePowerPoint ? "h-full mobile-xs:px-[20px] py-[20px]" : "min-h-empty-h mobile-xs:px-[24px] py-[43px] "
      } ${cart ? "tablet-xl:px-[60px]" : "tablet-sm:px-[60px]"}`}
    >
      <div className="w-full desktop-big:w-[1812px]">
        {
          <div className={`h-full flex flex-col ${isInsidePowerPoint ? "gap-[20px]" : ""}`}>
            {!isInsidePowerPoint && (
              <ul className="flex mx-[6.25px] gap-[16px] mb-[25px]">
                <li data-testid="search-message">
                  <p>
                    Search results for <strong>{data.search.query}</strong>:
                  </p>
                </li>
                {
                  <li
                    className={`group flex cursor-pointer ${
                      activeTab === "everywhere"
                        ? "font-bold text-sc-blue border-b border-sc-blue hover:opacity-80"
                        : ""
                    }`}
                    onClick={() => {
                      setActiveTab("everywhere")
                      setSearchData(data.search)
                    }}
                  >
                    <p title="Everywhere">Everywhere</p>
                  </li>
                }
                {
                  <li
                    className={`group flex cursor-pointer ${
                      activeTab === "tagsSlides"
                        ? "font-bold text-sc-blue border-b border-sc-blue hover:opacity-80"
                        : ""
                    }`}
                    data-testid="btn-tag"
                    onClick={() => {
                      setActiveTab("tagsSlides")
                      newSearchOnlyTags.data ? setSearchData(newSearchOnlyTags.data.search) : getNewSearchOnlyTags()
                    }}
                  >
                    <p title="Tags">Tags</p>
                  </li>
                }
              </ul>
            )}
            {
              <>
                {searchData.slides.length === 0 &&
                  searchData.presentations.length === 0 &&
                  searchData.collections.length === 0 && (
                    <div className="flex-1 flex flex-col items-center justify-center text-center">
                      <SearchEmptySvg />
                      <h2 className="text-[25px] text-sc-blue font-bold" data-testid="search-not-found">
                        SEARCH NOT FOUND
                      </h2>
                      <p className="mt-[15px] mb-[60px] font-light">
                        Sorry, we don't seem to have any <br />
                        slides matching your search.
                      </p>
                    </div>
                  )}

                {searchData.collections.length > 0 && (
                  <div className={`${!isInsidePowerPoint ? "mt-[43px]" : ""}`}>
                    <h2
                      className={`${
                        isInsidePowerPoint
                          ? "text-[9px] text-[#717171] uppercase mb-[15px]"
                          : "text-[17px] text-[#0F2642] font-bold"
                      }`}
                    >
                      Collections
                    </h2>
                    <div
                      className={`grid w-full ${
                        isInsidePowerPoint
                          ? "gap-[15px] min-[339px]:grid-cols-2"
                          : "mobile-sm:grid-cols-1 gap-[20px] pt-[25px] "
                      } ${
                        cart
                          ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                          : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                      }`}
                    >
                      {searchData.collections.map((colection) => (
                        <Presentation
                          active={colection.batchId === openedBatch?.id}
                          batch={colection}
                          handleViewBatch={isInsidePowerPoint ? handleViewBatch : null}
                          hideTools
                          id={colection.id}
                          isSearchPage={true}
                          key={colection.id}
                          name={colection.name || "No given name"}
                          noActions="true"
                          refetch={refetch}
                          setOpenedBatch={setOpenedBatch}
                          type="templates"
                        />
                      ))}
                    </div>
                  </div>
                )}

                {searchData.presentations.length > 0 && (
                  <div className={`${!isInsidePowerPoint ? "mt-[43px]" : ""}`}>
                    <h2
                      className={`${
                        isInsidePowerPoint
                          ? "text-[9px] text-[#717171] uppercase mb-[15px]"
                          : "text-[17px] text-[#0F2642] font-bold"
                      }`}
                    >
                      Presentations
                    </h2>
                    <div
                      className={`grid w-full ${
                        isInsidePowerPoint
                          ? "mt-[15px] gap-x-[15px] gap-y-[20px] min-[339px]:grid-cols-2"
                          : "mt-[25px] gap-[20px] mobile-sm:grid-cols-1"
                      } ${
                        cart
                          ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                          : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                      }`}
                    >
                      {searchData.presentations.map((presentation) => (
                        <Presentation
                          active={presentation.batchId === openedBatch?.id}
                          batch={presentation}
                          handleViewBatch={isInsidePowerPoint ? handleViewBatch : null}
                          hideTools={isInsidePowerPoint}
                          id={presentation.id}
                          isFavourite={presentation.isFavourite}
                          isSearchPage={true}
                          key={presentation.id}
                          name={presentation.name || "No given name"}
                          noActions
                          onFavouriteChange={(isFavourite) => {
                            const index = searchData.presentations.findIndex((p) => p.id === presentation.id)
                            if (index === -1) {
                              return
                            }
                            const presentations = [...searchData.presentations]

                            const currPresentation = { ...presentations[index] }
                            currPresentation.isFavourite = isFavourite
                            presentations.splice(index, 1, currPresentation)
                            setSearchData({ ...searchData, presentations })
                          }}
                          refetch={refetch}
                          setOpenedBatch={setOpenedBatch}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {searchData.slides.length > 0 && (
                  <InfiniteScroll
                    dataLength={searchData.slides.length}
                    hasMore={slidesOffset < searchData.slidesTotalCount}
                    loader={<MainLoader />}
                    next={fetchNext}
                    style={{ overflow: "visible" }}
                  >
                    <div
                      className={`flex flex-col ${!isInsidePowerPoint ? "mt-[43px] gap-[25px]" : ""}`}
                      style={{ clear: "both" }}
                    >
                      <h2
                        className={`${
                          isInsidePowerPoint
                            ? "text-[9px] text-[#717171] uppercase mb-[15px]"
                            : "text-[17px] text-[#0F2642] font-bold"
                        }`}
                      >
                        Slides
                      </h2>
                      <div className={`flex flex-col ${isInsidePowerPoint ? "gap-[20px]" : "gap-[25px]"}`}>
                        {searchData.slides.map((slide, index) => {
                          return (
                            <div key={slide.id}>
                              <div
                                className={`grid mobile-sm:grid-cols-1 w-full ${
                                  isInsidePowerPoint ? "gap-[10px]" : "gap-[20px]"
                                } ${
                                  cart
                                    ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                                    : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                                }`}
                              >
                                <Slide
                                  index={index}
                                  isHoverLayerLight={!isInsidePowerPoint}
                                  isSearchPage
                                  noActions
                                  slide={slide}
                                  batch={{ ...slide.presentations[0], slides: searchData.slides }}
                                  // FIX: BU: this causes error on safar @local dev when searching for "agenda" (Team Owner)
                                  refetch={refetch}
                                />
                                <SearchedSlideInfo
                                  isDupesOpen={openedDupesSlideId === slide.id}
                                  setOpenedDupesSlideId={setOpenedDupesSlideId}
                                  slide={slide}
                                />
                              </div>
                              {slide.dupes && (
                                <Collapse isOpened={openedDupesSlideId === slide.id}>
                                  <div
                                    className={`mt-[25px] border-y border-sc-line py-[30px] grid gap-x-[20px] gap-y-[30px] mobile-sm:grid-cols-1 w-full ${
                                      cart
                                        ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                                        : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                                    }`}
                                  >
                                    {slide.dupes.map((dupeSlide) => {
                                      const batch = dupeSlide.presentations[0]
                                      return (
                                        <div
                                          className="grid grid-cols-[31%_2fr] gap-[19px]"
                                          data-testid="search-slide-duplicate"
                                          key={batch.id}
                                        >
                                          {batch.thumbUrl ? (
                                            <picture className="cursor-pointer">
                                              <Link
                                                className="w-full"
                                                to={
                                                  batch.isMyPresentation
                                                    ? `/my-presentations/${batch.urlToken}/${slugify(batch.name)}/${
                                                        dupeSlide.id
                                                      }`
                                                    : `/library/${batch.urlToken}/${slugify(batch.name)}/${
                                                        dupeSlide.id
                                                      }`
                                                }
                                              >
                                                <img
                                                  alt={batch.name}
                                                  className="w-full object-cover"
                                                  src={`${batch.thumbUrl.replace("{width}", "278")}`}
                                                  srcSet={`${batch.thumbUrl.replace("{width}", "556")} 2x`}
                                                  style={{ imageRendering: "auto" }}
                                                />
                                              </Link>
                                            </picture>
                                          ) : (
                                            <div className="flex justify-center items-center h-[76%]">
                                              <SlidePlaceholder />
                                            </div>
                                          )}
                                          <div className="flex flex-col gap-[7px] justify-center">
                                            <Link
                                              to={
                                                batch.isMyPresentation
                                                  ? `/my-presentations/${batch.urlToken}/${slugify(batch.name)}/${
                                                      dupeSlide.id
                                                    }`
                                                  : `/library/${batch.urlToken}/${slugify(batch.name)}/${dupeSlide.id}`
                                              }
                                            >
                                              <h3 className="font-bold text-[#0F2642] line-clamp-1 cursor-pointer">
                                                {batch.name}
                                              </h3>
                                            </Link>

                                            <div className="text-[14px] text-[#A5A8AF]">
                                              {new Date(batch.updatedAt).toLocaleDateString("en-US", {
                                                month: "short",
                                                day: "2-digit",
                                                year: "numeric"
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </Collapse>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </InfiniteScroll>
                )}
              </>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default SearchContainer
