import React, { useRef, useEffect, useState, useCallback } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import { ApolloProvider } from "@apollo/client"
import LoggedOutWrapper from "./components/authentication/LoggedOutWrapper"
import RedirectUser from "./components/authentication/Redirect"
import AcceptInvitationPage from "./components/authentication/AcceptInvitationPage"
import MainPageContainer from "./components/MainPage"
import createClient from "./apolloClient"
import { AuthContext, useAuth } from "./hooks/AuthHook"
import { MainModalContext, useMainModal } from "./hooks/MainModalHook"
import { SlideModalContext, useSlideModal } from "./hooks/SlideModalHook"
import { PricingModalContext, usePricingModal } from "./hooks/PricingModalHook"
import { NotificationContext, useNotification } from "./hooks/NotificationHook"
import { ErrorPlaceholder } from "./components/utils/placeholders"
import { CurrentPresentationSlideContext, useCurrentPresentationSlide } from "./hooks/CurrentPresentationSlideHook"
import BatchContainer from "./components/slideShow/BatchContainer"
import * as Sentry from "@sentry/react"

const MainComponent = () => {
  const currentPresentationSlide = useCurrentPresentationSlide()
  const mainModal = useMainModal()
  const slideModal = useSlideModal()
  const pricingModal = usePricingModal()
  const notification = useNotification()
  const auth = useAuth({
    openMainModal: mainModal.openModal,
    openPricingModal: pricingModal.openModal
  })

  useEffect(() => {
    auth.checkCurrentUser()
  }, [])

  // TODO: BU: this probably could be simplified, needs to be refactored as it is super weird with the login errors :/
  const [error, setError] = useState(false)
  const errorHandler = useCallback(
    (err) => {
      // BU: error capturing tests
      Sentry.withScope((scope) => {
        // scope.setContext("ApolloClient ErrorHandler full error", JSON.stringify(err))
        scope.setExtra("forward", err.forward)
        scope.setExtra("graphQLErrors", err.graphQLErrors)
        scope.setExtra("networkError", err.networkError)

        Sentry.captureException(err)
      })

      if (err.networkError && err.networkError.statusCode === 401) {
        auth.logout()
      } else if (
        err.graphQLErrors[0].title === "invalid token" ||
        err.graphQLErrors[0].message === "Cannot read property 'id' of undefined"
      ) {
        auth.logout()
      } else if (err.graphQLErrors[0].message === "Forbidden" || err.graphQLErrors[0].message === "User deleted") {
        return
      } else {
        setError(true)
      }
    },
    [auth]
  )

  const clientRef = useRef()
  useEffect(() => {
    if (!clientRef.current) {
      clientRef.current = createClient(errorHandler)
    }
  }, [errorHandler])

  if (!clientRef.current) return null
  if (error) {
    return (
      <ErrorPlaceholder
        tryAgain={() => {
          window.location.replace("/")
        }}
      />
    )
  }
  // TODO: BU: this probably could be simplified end

  return (
    <DndProvider backend={HTML5Backend}>
      <MainModalContext.Provider value={mainModal}>
        <SlideModalContext.Provider value={slideModal}>
          <PricingModalContext.Provider value={pricingModal}>
            <NotificationContext.Provider value={notification}>
              <AuthContext.Provider value={auth}>
                <CurrentPresentationSlideContext.Provider value={currentPresentationSlide}>
                  <ApolloProvider client={clientRef.current}>
                    <div id="app-page">
                      <BrowserRouter>
                        <Switch>
                          {/* FIX: does not work, is this even used? */}
                          <Route component={LoggedOutWrapper} path="/users" />
                          {/* TODO: this redirect concept should be redone someday */}
                          <Route component={RedirectUser} path="/user/redirect" />
                          <Route component={AcceptInvitationPage} path="/accept_invitation/:memberId/:ott" />
                          <Route component={AcceptInvitationPage} path="/invitation/:memberId" />
                          <Route component={AcceptInvitationPage} path="/invitation/:memberId/:ott" />
                          <Route component={BatchContainer} path="/show/:shareToken" />
                          <Route component={MainPageContainer} path="/" />
                        </Switch>
                      </BrowserRouter>
                    </div>
                  </ApolloProvider>
                </CurrentPresentationSlideContext.Provider>
              </AuthContext.Provider>
            </NotificationContext.Provider>
          </PricingModalContext.Provider>
        </SlideModalContext.Provider>
      </MainModalContext.Provider>
    </DndProvider>
  )
}

export default MainComponent
