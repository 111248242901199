import React, { useContext } from "react"
import { ArrowDownIcon, FramesIcon } from "@/svg/SvgIcons"
import slugify from "slug/slug"
import { Link } from "react-router-dom"
import { ArrowUpIcon } from "../../svg/SvgIcons"
import { UserDataContext } from "../../hooks/UserDataHook"
import { splitSnippet } from "../../utils/helpers"
import { useIsInsidePowerPoint } from "../../context/IsInsidePowerPointContext"

const SearchedSlideInfo = ({ slide, isDupesOpen, setOpenedDupesSlideId }) => {
  const slideBatch = slide.presentations[0]
  const { user } = useContext(UserDataContext)
  const isInsidePowerPoint = useIsInsidePowerPoint()
  const formattedSnippet = slide.snippet && splitSnippet(slide.snippet)

  const handleToggleDupes = () => {
    if (isDupesOpen) {
      setOpenedDupesSlideId(null)
    } else {
      setOpenedDupesSlideId(slide.id)
    }
  }

  return (
    <div className={`flex flex-col gap-[14px] tablet-md:gap-[25px] ${isInsidePowerPoint ? "" : "pt-[23px]"}`}>
      <div className={`flex flex-col ${isInsidePowerPoint ? "gap-[4px]" : "gap-[7px]"}`}>
        <h2
          className={`text-[#0F2642] line-clamp-1 ${isInsidePowerPoint ? "text-[12px]" : "text-[15px]"}`}
          data-testid="searched-slide-info-title"
        >
          Slide in{" "}
          <Link
            to={{
              state: { currentPathname: slideBatch.name, batchId: slideBatch.id, slides: null },
              pathname: isInsidePowerPoint
                ? `/dashboard/${slideBatch.urlToken}/${slugify(slideBatch.name)}/${slide.id}`
                : slideBatch.isMyPresentation
                ? `/my-presentations/${slideBatch.urlToken}/${slugify(slideBatch.name)}/${slide.id}`
                : `/library/${slideBatch.urlToken}/${slugify(slideBatch.name)}/${slide.id}`
            }}
          >
            <b className="cursor-pointer">{slideBatch.name}</b>
          </Link>
        </h2>
        <div
          className={`flex gap-[10px] items-center whitespace-nowrap ${
            isInsidePowerPoint ? "text-[11px] text-[#717171]" : "text-[14px] text-[#A5A8AF]"
          }`}
        >
          {!isInsidePowerPoint && (
            <>
              <span className="overflow-hidden text-ellipsis">
                {user.userId === slideBatch.creator.id
                  ? "You"
                  : `${slideBatch.creator.firstName} ${slideBatch.creator.lastName}`}
              </span>
              <svg fill="none" height="4" viewBox="0 0 4 4" width="4" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2.06494" cy="2" fill="#A5A8AF" r="1.5" />
              </svg>
            </>
          )}
          <span>
            {new Date(slideBatch.updatedAt).toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric"
            })}
          </span>
        </div>
      </div>
      {!isInsidePowerPoint && slide.snippet && <span dangerouslySetInnerHTML={{ __html: formattedSnippet }} />}
      {slide.dupes?.length > 0 && (
        <div className="flex-1 flex items-end">
          <p>
            {" "}
            <FramesIcon />
            <button onClick={handleToggleDupes}>
              {" "}
              <span className="ml-[9px] mr-[12px]">
                Found in{" "}
                <b>
                  {slide.dupes.length} other asset{slide.dupes.length > 1 ? "s" : ""}
                </b>
              </span>
              {isDupesOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </button>
          </p>
        </div>
      )}
    </div>
  )
}

export default SearchedSlideInfo
