import { useQuery, gql, useLazyQuery } from "@apollo/client"
import React, { useContext, useRef, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { includes, isNil } from "lodash"
import GlobalDropzone from "../utils/upload/GlobalDropzone"
import Presentation from "../presentation/Presentation"
import { CollectionEmptySvg } from "../../svg/EmptyContainerSvgs"
import { PricingModalContext } from "../../hooks/PricingModalHook"
import { ErrorPlaceholder, MainLoader } from "../utils/placeholders"
import { UserDataContext } from "../../hooks/UserDataHook"
import { Batch, Slide } from "../../graphql/types/queries/batch"
import { SharedEmptySvg, SharedTeamOwnerEmptySvg } from "../../svg/EmptyContainerSvgs"
import PresentationPlaceholder from "../presentation/PresentationPlaceholder"
import { HandleDropOptions } from "../utils/upload/types"
import { fileUpload } from "../utils/actions"
import { CategoryContext } from "@/context/CategoryContext"
import slugify from "slug"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"

const MY_PRESENTATIONS = gql`
  query myBatches {
    myBatches {
      _id
      id
      name
      mode
      batchId
      icon
      labels
      urlToken
      isFavourite
      isMyPresentation
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      creator {
        id
        firstName
        lastName
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        downloadUrl
        linksDataHeight
        linksDataWidth
        state
        isFavourite
      }
      category {
        _id
      }
    }
  }
`

const GET_PRESENTATION = gql`
  query presentation($id: String!) {
    presentation(id: $id) {
      _id
      id
      name
      mode
      batchId
      icon
      labels
      urlToken
      isFavourite
      creator {
        id
        firstName
        lastName
      }
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        downloadUrl
        linksDataHeight
        linksDataWidth
        state
        isFavourite
      }
      category {
        _id
      }
    }
  }
`

const options = ["All", "Management", "Events", "Sales"]

// BU: my presentations
const BatchesContainer = () => {
  const history = useHistory()
  const [batchFilter, setBatchFilter] = useState<string>("All")
  const [currentJobs, setCurrentJobs] = useState<Record<string, string>>({})
  const [uploadedEntityCount, setUploadedEntityCount] = useState(0)
  const { openModal } = useContext(PricingModalContext)
  const {
    user,
    user: { isEditModeActive }
  } = useContext(UserDataContext)
  const { addPresentation } = useContext(CategoryContext)
  const location = useLocation()
  const pickerInputRef = useRef<HTMLInputElement | null>(null)
  const currentPath = location.pathname
  const isSharedPath = currentPath === "/shared" // This is probably not needed and will be deleted later
  const isMyPresentationPath = currentPath === "/my-presentations"
  const isInsidePowerPoint = useIsInsidePowerPoint()
  const { urlToken } = useParams()

  const [getPresentationData, { stopPolling: stopPresentationDataPolling }] = useLazyQuery(GET_PRESENTATION, {
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      if (!presentation) {
        stopPresentationDataPolling()
        return
      }
      const slides = presentation.slides
      if (!slides?.length) return
      if (slides[0]?.state === "uploaded") {
        stopPresentationDataPolling()
      }
    }
  })

  const {
    data,
    loading,
    error,
    refetch: refetchSlides
  } = useQuery(MY_PRESENTATIONS, {
    context: { isUsingNewScApi: true }
  })

  const handleSetCurrentJobs = (jobs: Record<string, string>) => setCurrentJobs({ ...currentJobs, ...jobs })
  const handleRefetch = async () => await refetchSlides()

  const handleViewBatch = (batchUrlToken: string, pathName: string, slides: Slide[]) => {
    if (isInsidePowerPoint) {
      history.push({
        pathname: `/dashboard/${batchUrlToken}/${slugify(pathName)}`,
        state: { slides, currentPathname: pathName }
      })
    } else if (urlToken === batchUrlToken) {
      history.push("/my-presentations")
    } else {
      history.push(`/my-presentations/${batchUrlToken}/${slugify(pathName)}`)
    }
  }

  if (loading) return <MainLoader />
  if (error) return <ErrorPlaceholder />
  const { group, cart } = user
  const { team, sharedBatches, myBatches, allowUploads } = data

  // const renderPlaceholder = () => {
  //   if (team) {
  //     if (team.members.length === 0) {
  //       return (
  //         <div className="empty-container team-owner">
  //           <SharedTeamOwnerEmptySvg />
  //           <h2>SHARE PRESENTATION WITH TEAM</h2>
  //           <p>
  //             In order to share presentations with your team <br />
  //             you need to add team members first.
  //           </p>
  //           <button className="btn btn-lightblue" onClick={() => history.push("/myprofile/myteam")} type="button">
  //             Add team member
  //           </button>
  //         </div>
  //       )
  //     }
  //
  //     return (
  //       <div className="empty-container">
  //         <SharedEmptySvg />
  //         <h2>NO SHARED PRESENTATIONS YET</h2>
  //         <p>You haven’t shared any presentations yet.</p>
  //       </div>
  //     )
  //   }
  //
  //   return (
  //     <div className="empty-container">
  //       <SharedEmptySvg />
  //       <h2>NO SHARED PRESENTATIONS YET</h2>
  //       <p>
  //         There are no presentations shared <br />
  //         by the team owner yet.
  //       </p>
  //     </div>
  //   )
  // }

  const calculateCanUpload = () =>
    !!user.subscription || myBatches.map((batch: Batch) => batch.uploaded).filter(Boolean).length < 3

  if (!data) return null

  const handleDrop =
    ({
      getJob,
      getUploadFileUrl,
      setShowUploadProgress,
      setFilesToUpload,
      filesWithProgress,
      changeProgress
    }: HandleDropOptions) =>
    async (files: File[]) => {
      setUploadedEntityCount(files.length)
      setShowUploadProgress(true)

      const uploadPresentationJobs: Record<string, string> = {}

      const filePromises = files.map(async (file) => {
        const { data } = await getUploadFileUrl()
        await fileUpload(file, data.getUploadFileUrl.url, changeProgress)
        const {
          data: { createPresentation: createBatchData }
        } = await addPresentation({
          variables: {
            shared: isSharedPath,
            isMyPresentation: isMyPresentationPath,
            fileToken: data.getUploadFileUrl.token,
            name: file.name.split(".")[0]
          }
        })

        if (createBatchData.success) {
          uploadPresentationJobs[createBatchData.presentation.id] = createBatchData.job.id

          if (handleSetCurrentJobs) handleSetCurrentJobs(uploadPresentationJobs)
        }

        await getJob({
          variables: {
            queueName: "Slide",
            jobIds: [...Object.values(currentJobs), ...Object.values(uploadPresentationJobs)]
          }
        })
        await handleRefetch()
        setUploadedEntityCount((prevCount) => prevCount - 1)
      })

      await Promise.all(filePromises)
      setShowUploadProgress(false)
      setFilesToUpload({})
      filesWithProgress.current = {}
    }

  const handlePicker = () => pickerInputRef.current?.click()
  const canUpload = calculateCanUpload()

  return (
    <div
      className={`flex justify-center ${
        isInsidePowerPoint
          ? "h-full mobile-xs:px-[20px]"
          : cart
          ? "tablet-xl:px-[60px]"
          : "tablet-sm:px-[60px] min-h-empty-h mobile-xs:px-[24px] py-[43px]"
      }`}
    >
      {(isSharedPath ? isEditModeActive : allowUploads || isMyPresentationPath) && (
        <div className="w-0 h-0 hide">
          <GlobalDropzone
            handleDrop={handleDrop}
            handlePicker={handlePicker}
            ref={pickerInputRef}
            refetch={refetchSlides}
          />
        </div>
      )}
      {isSharedPath && isEditModeActive && (
        <div className="header-container ml-[12px] mb-[10px]">
          <div className="upload-pres" onClick={handlePicker}>
            <svg version="1.1" viewBox="0 0 12 12" x="0px" y="0px">
              <path
                d="M11.5,5.5h-5v-5C6.5,0.2,6.3,0,6,0S5.5,0.2,5.5,0.5v5h-5C0.2,5.5,0,5.7,0,6s0.2,0.5,0.5,0.5h5v5
                    C5.5,11.8,5.7,12,6,12s0.5-0.2,0.5-0.5v-5h5C11.8,6.5,12,6.3,12,6S11.8,5.5,11.5,5.5z"
              />
            </svg>
            Upload
          </div>
        </div>
      )}
      {/*{isSharedPath && sharedBatches.length === 0 && renderPlaceholder()}*/}
      {!isSharedPath && myBatches.length === 0 && uploadedEntityCount === 0 && (
        <div className="flex flex-col items-center justify-center text-center">
          <CollectionEmptySvg />
          <h2 className="text-[25px] text-sc-blue font-bold">YOU DON’T HAVE ANY PRESENTATIONS</h2>
          {!isInsidePowerPoint && (
            <p className="mt-[15px] mb-[45px] font-light">
              Upload your presentations or save a group of slides <br /> before downloading them.
            </p>
          )}
          {(allowUploads || isMyPresentationPath) && !isInsidePowerPoint && (
            <button
              className="hover:bg-[#136FD5] bg-[#1683FB] transition-colors duration-200 text-white rounded-[26px] w-[204px] py-[9px] px-[29px] text-base desktop-2xl:text-lg desktop-2xl:w-[268px] desktop-2xl:py-[12px] desktop-2xl:px-[35px]"
              onClick={() => (canUpload ? handlePicker() : openModal("pricing"))}
              type="button"
            >
              Upload
            </button>
          )}
        </div>
      )}
      {((isSharedPath && sharedBatches.length > 0) ||
        (!isSharedPath && myBatches.length > 0) ||
        uploadedEntityCount > 0) && (
        <div className="w-full desktop-big:w-[1812px]">
          <div
            className={`grid mobile-sm:grid-cols-1 w-full ${
              isInsidePowerPoint ? "my-[21px] gap-x-[15px] gap-y-[20px]" : "mt-[25px] gap-[20px]"
            } ${
              cart
                ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
            }`}
          >
            {!isInsidePowerPoint && (
              <div className="aspect-[490/357]">
                <GlobalDropzone
                  handleDrop={handleDrop}
                  handlePicker={() => (canUpload ? handlePicker() : openModal("pricing"))}
                  isButton
                  ref={pickerInputRef}
                  refetch={refetchSlides}
                />
              </div>
            )}
            {uploadedEntityCount > 0 &&
              Array.from(Array(uploadedEntityCount).keys()).map((index) => <PresentationPlaceholder key={index} />)}

            {(isSharedPath ? sharedBatches : myBatches)
              .filter(
                (batch: Batch) =>
                  (batchFilter === "All" ||
                    includes(
                      batch.labels.map((label) => label.toLowerCase()),
                      batchFilter.toLowerCase()
                    )) &&
                  !isNil(batch)
              )
              .map((batch: Batch) => {
                if (isInsidePowerPoint) {
                  return (
                    <Presentation
                      active={false}
                      batch={batch}
                      handleViewBatch={handleViewBatch}
                      hideTools
                      id={batch.id}
                      isFavourite={batch.isFavourite}
                      isSearchPage={true}
                      key={batch.id}
                      name={batch.name || "No given name"}
                      noActions
                      refetch={() => {
                        getPresentationData({ variables: { id: batch.batchId } })
                      }}
                    />
                  )
                }
                return (
                  <Presentation
                    active={batch.urlToken === urlToken}
                    batch={batch}
                    handleViewBatch={handleViewBatch}
                    id={batch.batchId}
                    isFavourite={batch.isFavourite}
                    key={batch.batchId}
                    name={batch.name || "No given name"}
                    refetch={() => {
                      getPresentationData({ variables: { id: batch.batchId } })
                    }}
                    type={isSharedPath ? "sharedBatches" : isMyPresentationPath ? "myBatches" : "batches"}
                  />
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

export default BatchesContainer
